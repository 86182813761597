.archive{
    &_footer{
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
}
nav {
    .pagination {
        display: inline-flex !important;
    }
}
.border-bottom-primary {
    border-bottom: 0.10rem solid #B20000 !important;
}
.filtre-btn{
    padding: 2px 15px !important;
    margin-top: -5px !important;

}
#filter-init{
    position: absolute;
    right: 10px;
    top: 10px;
}
.badge-filter{
    padding: 2px 5px 3px 5px;
    margin-left:5px;
    margin-top: 3px;
}
.archive__numero{
    position: absolute;
    left: calc( 50% - 27px);
    top: 60px;
    color: white;
}
.empty{
    margin-top: 15%;
    text-align: center;
    color: #ccc;
    font-size: 16px;
}
.archive{
    position: relative;
}
.dashboard{
    &_table{
        height: 346px;
        overflow: auto;
        padding: 10px;
    }
}

.bg-gradient{
    background: linear-gradient(135deg, #008038 0%, #214999 100%) !important;
}
.btn-gradient{
    background-color: linear-gradient(135deg, #008038 0%, #214999 100%) !important;
    color: #fff;
    border-color: #214999;
    box-shadow: none;
}